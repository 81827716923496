<template id="zuzahlung">
    <v-container v-if="$store.state.defCache" fluid class="pa-0">
        <v-row class="justify-start">
            <v-col cols="12">

                <v-form ref="zuzForm" lazy-validation>
                    <v-card borderless>
                        <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                            Zuzahlungsprüfung
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">
                            <v-container fluid class="px-0 pb-0">
                                <v-row>
                                    <div class="options">
                                        <v-radio-group v-model="selectedKasse"  row>

                                            <v-radio v-for="n in kassen"
                                                     :key="n.mandant"
                                                     :label="n.bezeichnung"
                                                     :value="n"
                                                     :disabled="isLoading">
                                                <!--{{ n.Bbzeichnung }}-->
                                                <template slot="label">
                                                    <div>
                                                        <!--<div class="text-subtitle-2 pa-0">{{ n.Bezeichnung }}</div>-->
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-img v-if="n.mandant == 1" src="../assets/Logo_BARMER_1000x700.png"
                                                                       max-height="40px" min-width="30px" max-width="6vw" contain
                                                                       class="mx-auto" v-bind:class="{ disabled: n.mandant!==selectedKasse.mandant }" v-on="on" />
                                                            </template>
                                                            <span>{{ n.bezeichnung }}</span>
                                                        </v-tooltip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-img v-if="n.mandant == 66" src="../assets/Logo_MKK_1000x700.png" class="mx-auto"
                                                                       max-height="40px" min-width="30px" max-width="6vw" contain
                                                                       v-bind:class="{ disabled: n.mandant!==selectedKasse.mandant }" v-on="on" />
                                                            </template>
                                                            <span>{{ n.bezeichnung }}</span>
                                                        </v-tooltip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-img v-if="n.mandant == 4" src="../assets/Logo_DAK_1000x700.png" class="mx-auto"
                                                                       max-height="40px" min-width="30px" max-width="6vw" contain
                                                                       v-bind:class="{ disabled: n.mandant!==selectedKasse.mandant }" v-on="on" />
                                                            </template>
                                                            <span>{{ n.bezeichnung }}</span>
                                                        </v-tooltip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-img v-if="n.mandant == 3" src="../assets/Logo_HEK_1000x700.png" class="mx-auto"
                                                                       max-height="40px" min-width="30px" max-width="6vw" contain
                                                                       v-bind:class="{ disabled: n.mandant!==selectedKasse.mandant }" v-on="on" />
                                                            </template>
                                                            <span>{{ n.bezeichnung }}</span>
                                                        </v-tooltip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-img v-if="n.mandant == 74" src="../assets/Logo_KKH_1000x700.png" class="mx-auto"
                                                                       max-height="40px" min-width="30px" max-width="6vw" contain
                                                                       v-bind:class="{ disabled: n.mandant!==selectedKasse.mandant }" v-on="on" />
                                                            </template>
                                                            <span>{{ n.bezeichnung }}</span>
                                                        </v-tooltip>
                                                    </div>
                                                </template>

                                            </v-radio>
                                        </v-radio-group>
                                    </div>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="6" md="4" lg="3">
                                        <v-text-field label="Versichertennummer" v-model="v_versNr"
                                                      @keyup.enter="tryGetZuzBefreiung" @click:clear="resetData"
                                                      autocomplete="off" dense clearable />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3" lg="2">
                                        <v-text-field label="Abgabedatum" :value="toLocaleDateFormat(v_datum)"
                                                      @click="v_showPicker = true;"
                                                      autocomplete="off" dense readonly>

                                            <template v-slot:append>
                                                <v-menu v-model="v_showPicker"
                                                        :close-on-content-click="false"
                                                        offset-x>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" color="primary">$vuetify.icons.calendar</v-icon>
                                                    </template>
                                                    <v-date-picker v-model="v_datum" :min="v_datum_min" :max="v_datum_max"
                                                                   @input="v_showPicker = false; tryGetZuzBefreiung();" />
                                                </v-menu>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary" type="submit" :disabled="!isValid || isLoading"
                                                :loading="isLoading"
                                               @click.stop.prevent="tryGetZuzBefreiung">
                                            Suchen
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="10" lg="8">
                                        <div v-if="v_isMitarbeiter">
                                            <v-alert type="warning" :value="v_isMitarbeiter" border="left" class="text-h6" prominent>
                                                <div>Zu diesem Versicherten dürfen die Daten nicht angezeigt werden.</div>
                                            </v-alert>
                                        </div>
                                        <div v-else>

                                            <div v-show="v_isZuzBefreit !== null || v_keineDaten">
                                                <v-alert :color="v_isZuzBefreit ? 'warning' : 'primary'"
                                                         :value="v_isZuzBefreit !== null"
                                                         class="white--text mb-0" border="left">
                                                    <v-list color="transparent" class="py-0" disabled>
                                                        <v-list-item-group>
                                                            <v-list-item two-line>
                                                                <v-list-item-icon>
                                                                    <v-icon class="black--text">
                                                                        mdi-sim-alert
                                                                    </v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    <v-list-item-title class="text-h5 black--text">
                                                                        Stammdaten vom {{ v_datenstand }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle class="text-subtitle-1 black--text text-wrap">
                                                                        Zu diesem Datum wurde der Datenbestand von der Kasse bereitgestellt.
                                                                        Zum einem späteren Zeitpunkt könnten die angezeigten Informationen unzutreffend sein.
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item two-line>
                                                                <v-list-item-icon>
                                                                    <v-icon class="black--text">
                                                                        {{ v_isZuzBefreit ? 'mdi-currency-eur-off' : 'mdi-currency-eur' }}
                                                                    </v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    <v-list-item-title class="text-h4 black--text">
                                                                        {{ v_isZuzBefreit ? 'Zuzahlungsbefreit' : 'Nicht zuzahlungsbefreit' }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle class="text-subtitle-1 black--text text-wrap">
                                                                        Dieser Status ist für die/den Versicherte(n) hinterlegt.
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item two-line>
                                                                <v-list-item-icon>
                                                                    <v-icon class="black--text">
                                                                        mdi-account
                                                                    </v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    <v-list-item-title class="text-h5 black--text">
                                                                        {{ v_versName }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle class="text-subtitle-1 black--text text-wrap">
                                                                        Dieser Name ist in den Stammdaten hinterlegt.
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item two-line>
                                                                <v-list-item-icon>
                                                                    <v-icon class="black--text">
                                                                        mdi-medical-bag
                                                                    </v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    <v-list-item-title class="text-h5 black--text">
                                                                        {{ replace(v_kasseName, / /g, '&nbsp;') }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle class="text-subtitle-1 black--text text-wrap">
                                                                        Der Versicherte war/ist zum Datum des Datenstandes (s.o.) bei dieser Kasse versichert.
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list-item-group>
                                                    </v-list>
                                                </v-alert>

                                                <v-alert type="error" :value="v_keineDaten" border="left" class="text-h6" prominent>
                                                    <div>Zu diesem Versicherten sind bei dieser Kasse leider keine Daten vorhanden.</div>
                                                    <div>Bitte prüfen Sie die Versichertennummer und die ausgewählte Kasse.</div>
                                                </v-alert>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-form>

            </v-col>
        </v-row>

        <!-- Dialog Captcha fehlgeschlagen -->
        <CaptchaFailDialog :isVisible="showDialogCaptchaFailure" />

    </v-container>
</template>

<script>
    import eventBus from '@/main';
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue'



    export default {

        name: 'Zuzahlung',

        components: {
            CaptchaFailDialog
        },

        data: () => ({
            v_kasse: '',
            v_versNr: '',
            v_datum: new Date().toISOString().substr(0, 10),
            v_isZuzBefreit: null,
            v_kasseName: null,
            v_versName: null,
            v_showPicker: false,
            v_keineDaten: false,
            v_datenstand: null,
            //paramsHaveChanged: false,
            showDialogCaptchaFailure: false,
            selectedKasse: {},
            radioGroup: 1,
            isLoading: false,
            v_isMitarbeiter: false
        }),
        
        computed: {
            v_datum_min() {
                let min = new Date();
                min.setFullYear(min.getFullYear() - 1);
                return min.toISOString().substr(0, 10);
            },

            v_datum_max() {
                return new Date().toISOString().substr(0, 10);
            },

            kassen() { 
                let ml = this.$store.state.currentUser?.mandanten?.map(m => ({ ...m })) ; 
                return ml;
            },

            isValid() {
                return !!this.selectedKasse?.mandant  && !!this.v_versNr && !this.isLoading ;
            }
        },

        watch: {
            v_versNr() {
                this.initVersData();
            },
            v_datum() {
                this.initVersData();
            },

            'selectedKasse.mandant'() {
                this.initVersData();
            },
        },
 
        activated() {
            this.$nextTick(() => {
                this.initialData();
            });
        },

        methods: {

            initialData() {
                    this.v_kasse= '';
                    this.v_versNr= '';
                    this.v_datum= new Date().toISOString().substr(0, 10);
                    this.v_isZuzBefreit= null;
                    this.v_kasseName= null;
                    this.v_versName= null;
                    this.v_showPicker= false;
                    this.v_keineDaten= false;
                    this.v_datenstand= null;
                    //this.paramsHaveChanged= false;
                    this.showDialogCaptchaFailure= false;
                    this.selectedKasse= {};
                    this.radioGroup= 1;
                    this.isLoading = false;
                    this.v_isMitarbeiter = false;
            },

            initVersData() {
                this.v_isZuzBefreit = null;
                this.v_kasseName = null;
                this.v_versName = null;
                this.v_datenstand = null;
                this.v_keineDaten = false;
                this.v_isMitarbeiter = false;
            },
            async tryGetZuzBefreiung() {
               
                //console.log('this.v_versNr: ', this.v_versNr, 'this.v_datum: ', this.v_datum, 'this.selectedKasse.Mandant: ', this.selectedKasse.Mandant, 'this.paramsHaveChanged :', this.paramsHaveChanged);
                if (this.v_versNr && this.v_datum && this.selectedKasse.mandant && !this.isLoading) {
                    //console.log('this.isLoading: ', this.isLoading);
                    try {
                        // get captcha token from Google
                        await this.$recaptchaLoaded();
                        const token = await this.$recaptcha('zuzahlung');

                        // verify by API
                        const rsp = await this.$http.get("auth/verifycaptcha", { params: { token: token } });
                        if (rsp.status !== 200)
                            throw new Error('Ungültiges Captcha');
                    }
                    catch {
                        this.showDialogCaptchaFailure = true;
                        return;
                    }
                    this.isLoading = true;
                    
                    eventBus.$emit('resetLogoutTimer', {});
                    //this.paramsHaveChanged = false;
                    await this.$http.get("zuz/pruefung", {
                        params: {
                            mandant: this.selectedKasse.mandant,
                            versNr: this.v_versNr,
                            refDatum: this.v_datum
                        }
                    }).then(rsp => {
                        if (rsp.status === 200) {
                                this.v_isZuzBefreit = rsp.data.zuzahlung;
                                this.v_kasseName = rsp.data.kasseBezeichnung;
                                this.v_versName = rsp.data.name;
                                this.v_datenstand = rsp.data.zustandVom;
                                this.v_keineDaten = !rsp.data.zuzahlung && !rsp.data.kasseBezeichnung && !rsp.data.name && !this.v_isMitarbeiter;
                                this.v_isMitarbeiter = rsp.data.isMA;
                          }
                        else {
                            this.v_isZuzBefreit = null;
                            this.v_kasseName = null;
                            this.v_versName = null;
                            this.v_datenstand = null;
                            this.v_keineDaten = true;
                            this.v_isMitarbeiter = false;
                        }
                        this.isLoading = false
                    }).catch(error => {
                        if (error.response) {
                            this.v_isZuzBefreit = null;
                            this.v_kasseName = null;
                            this.v_versName = null;
                            this.v_datenstand = null;
                            this.v_keineDaten = true;
                            this.v_isMitarbeiter = false;
                        }
                        this.isLoading = false
                    });
                }
            },
            toLocaleDateFormat(isoDate) {
                if (isoDate) {
                    let locale = this.currentLocale;
                    let options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                    return new Date(isoDate).toLocaleDateString(locale, options);
                }
            },
            replace(str, token, replacement) {
                return str?.replace(token, replacement);
            },
            resetData() {
                this.initialData();
            },
        }
    }
</script>
<style scoped>
  
    .active {
        filter: grayscale(100%);
        /*filter: sepia(100%) hue-rotate(19deg) saturate(98) brightness(98%);*/
        /*border: 3px solid #fff;*/
    }
    .disabled {
        /*pointer-events: none;*/
        color: #bfcbd9;
        /*cursor: not-allowed;*/ 
        background-image: none;
        background-color: #eef1f6;
        border-color: #d1dbe5;
        filter: grayscale(100%);
    }
   >>> .v-input--selection-controls__input {
        margin-right: 0px;
    }


</style>